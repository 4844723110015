<template>
  <body-layout :isUseBodyLayoutStyle="isUseBodyLayoutStyle" :title="title" v-has="viewPermissionString">
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="deletePermissionString"
        >删除</a-button
      >
      <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        :disabled="selectedRowKeys.length >= 2"
        icon="cloud"
        v-has="editPermissionString"
        >同步</a-button
      >
      <a-button type="primary" @click="openAddForm" v-has="addPermissionString">{{ btnTitle || '新建' }}</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px" v-if="false">
        <i class="anticon anticon-info-circle ant-alert-icon"></i>已选择<a class="mx-1 text-bold">{{
          selectedRowKeys.length
        }}</a
        >项&nbsp;&nbsp;
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <!-- <a-table  v-has="viewPermissionString" -->
      <a-table
        style="border-top:1px solid rgb(232,232,232)"
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="
          dataSource.filter(item => {
            return isView(item)
          })
        "
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="rowSelection"
        @change="handleTableChange"
        :scroll="{ x: '1200px', y: 'calc(100vh - 405px)' }"
      >
        <span slot="classId" slot-scope="text, record">
          <label v-for="item in configFormData.classIds" :key="item.value">
            <span v-if="item.value === text">
              {{ item.label }}
            </span>
          </label>
        </span>
        <span slot="typeId" slot-scope="text, record">
          <div v-if="!propsFilters.typeId || isDefaultTypeId">
            <label v-for="item in configFormData.typeIds" :key="item.value">
              <span v-if="item.value === text">
                {{ item.label }}
              </span>
            </label>
          </div>
        </span>

        <span slot="departmentNames" slot-scope="text, record">
          <div>
            <a-tag v-for="one in record.departments" :key="one.departId">
              {{ one.departName }}
            </a-tag>
          </div>
        </span>

        <span slot="positionNames" slot-scope="text, record">
          <div>
            <a-tag v-for="one in record.positions" :key="one.positionId"
              >{{ one.positionName + '-' }}{{ one.type == 'VIEW' ? '查看' : '编辑' }}</a-tag
            >
          </div>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->
    <guide-modal
      v-if="!isAdd"
      :open="drawerShow"
      :userInfo="userInfo"
      :isAdmin="isAdmin"
      :cur-row="curRow"
      :isForm="isNewForm"
      :departmentIds='departmentIds'
      @closed="drawerClosed"
      :permission="editPermissionString"
      :propsUrl="url"
      :productionId="productionId"
      :platformId="platformId"
      :departmentId="propsDepartmentId"
      :props-filters="propsFilters"
      :is-preview="false"
      :isMaskClosable="isMaskClosable"
      :hide-edit-btn="false"
      @onConfirm="onConfirm"
    ></guide-modal>
    <guide-modal-table
      v-else
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
      :permission="editPermissionString"
      :departmentIds='departmentIds'
      :propsUrl="url"
      :productionId="productionId"
      :platformId="platformId"
      :departmentId="propsDepartmentId"
      :props-filters="propsFilters"
      :is-preview="false"
      :isMaskClosable="isMaskClosable"
      :userInfo="userInfo"
      :isAdmin="isAdmin"
      :hide-edit-btn="false"
      @onConfirm="onConfirm"
    ></guide-modal-table>
    <sync-modal
      ref="syncModal"
      url="/rule/sync"
      :selectedRowKeys="selectedRowKeys"
      :selectionRows="selectionRows"
      idType="ruleIds"
    />
  </body-layout>
</template>

<script>
import GuideModal from './guide-modal'
import syncModal from '@/components/sync-modal'
import GuideModalTable from './guide-modal-table'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import JInput from '@/components/jeecg/JInput'
import { configFormData } from './config'

export default {
  name: 'guide',
  mixins: [JeecgListMixin],
  components: {
    GuideModal,
    syncModal,
    JInput,
    GuideModalTable
  },
  props: {
    departmentIds: {
      type: Array,
      default: () => []
    },
    isDetail: {
      type: Boolean,
      default: () => false
    },
    addPermissionString: {
      type: String,
      default: ''
    },
    editPermissionString: {
      type: String,
      default: ''
    },
    deletePermissionString: {
      type: String,
      default: ''
    },
    viewPermissionString: {
      type: String,
      default: ''
    },
    title: '',
    btnTitle: '',
    isDefaultTypeId: {
      type: Boolean,
      default: () => false
    },
    isPreview: {
      type: Boolean,
      default: () => false
    },
    isMaskClosable: {
      type: Boolean,
      default: () => false
    },
    hideEditBtn: {
      type: Boolean,
      default: () => false
    },
    propsUrl: {
      type: Object,
      default: () => ({
        list: '/guide/list',
        delete: '/guide/delete',
        deleteBatch: '/guide/delete_batch',
        edit: '/guide/edit',
        add: '/guide/add'
      })
    },
    propsProductionId: {
      type: [String, Number],
      default: ''
    },
    propsPlatformId: {
      type: [String, Number],
      default: ''
    },
    propsDepartmentId: {
      type: String,
      default: ''
    },
    propsFilters: {
      type: Object,
      default: () => ({})
    },
    propsIpagination: {
      current: 1,
      pageSize: 10,
      pageSizeOptions: ['10', '15', '20', '30', '40', '50'],
      showTotal: (total, range) => {
        return range[0] + '-' + range[1] + ' 共' + total + '条'
      },
      showQuickJumper: true,
      showSizeChanger: true,
      total: 0
    },
    isUseBodyLayoutStyleProps: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      configFormData,
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: this.isDetail ? 60 : 50
        },
        {
          title: '名称',
          dataIndex: 'guideName',
          width: 170
        },
        {
          title: '分类',
          dataIndex: 'typeId',
          scopedSlots: { customRender: 'typeId' },
          width: 180
        },
        {
          title: '属性',
          dataIndex: 'classId',
          scopedSlots: { customRender: 'classId' },
          width: 120
        },
        /*         {
          title: '适用部门',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 280,
          colSpan: this.isDetail && 0
        }, */
        {
          title: '适用职位',
          scopedSlots: { customRender: 'positionNames' },
          dataIndex: 'positions',
          width: 180
        }
      ],
      url: this.propsUrl,
      productionId: this.propsProductionId,
      platformId: this.propsPlatformId,
      id: this.propsProductionId || this.propsPlatformId,
      isUseBodyLayoutStyle: this.isUseBodyLayoutStyleProps,
      disableMixinCreated: true
    }
  },
  created() {
    console.log({ 'this.propsFilters': this.propsFilters })
    if (this.propsFilters.typeId) {
      this.columns = this.columns.filter(({ dataIndex }) => dataIndex != 'typeId')
    }
    this.isDefaultTypeId && (this.propsFilters.typeId = '1')
    this.filters = { ...this.filters, ...this.propsFilters }
    console.log({ 'this.filters': this.filters })
    this.loadData()
    //初始化字典配置 在自己页面定义
    this.initDictConfig()
  },
  computed: {
    rowSelection() {
      return {
        hideDefaultSelections: false,
        selectedRowKeys: this.selectedRowKeys,
        onChange: false ? this.onPreviewSelectChange : this.onSelectChange,
        columnWidth: this.isDetail ? 61 : 51,
        getCheckboxProps: record => ({
          props: {
            disabled: this.isSucDis(record)
          }
        })
      }
    }
    /*     addPermissionString() {
      if (this.productionId != '') {
        return 'production:guide:button:add'
      } else if (this.platformId != '') {
        return 'platform:guide:button:add'
      } else {
        return 'system:guide:list:button:add'
      }
    },
    deletePermissionString() {
      if (this.productionId != '') {
        return 'production:guide:button:delete'
      } else if (this.platformId != '') {
        return 'platform:guide:button:delete'
      } else {
        return 'system:guide:list:button:delete'
      }
    },
    editPermissionString() {
      if (this.productionId != '') {
        return 'production:guide:button:edit'
      } else if (this.platformId != '') {
        return 'platform:guide:button:edit'
      } else {
        return 'system:guide:list:button:edit'
      }
    },
    viewPermissionString() {
      if (this.productionId != '') {
        return 'production:guide:page:view'
      } else if (this.platformId != '') {
        return 'platform:guide:page:view'
      } else {
        return 'system:guide:list:page:view'
      }
    }, */
  },
  methods: {
    isSucDis(item) {
      let dis = true
      for (let p of this.userInfo.positions) {
        if (
          item.positions?.some(i => {
            return i.positionId == p.id && i.type == 'EDIT'
          })
        ) {
          dis = false
          break
        }
      }
      if (this.isAdmin) dis = false
      return dis
    },
    isView(item) {
      let dis = false
      for (let p of this.userInfo.positions) {
        if (
          item.positions?.some(i => {
            return i.positionId == p.id && (i.type == 'VIEW' || i.type == 'EDIT')
          })
        ) {
          dis = true
          break
        }
      }
      if (this.isAdmin) dis = true
      return dis
    },
    openAddForm() {
      this.isAdd = true
      this.drawerShow = true
      this.isNewForm = true
    },
    handleSelectChange(val) {
      this.classId = val
    },
    onPreviewSelectChange(selectedRowKeys, selectionRows) {
      console.log(selectedRowKeys, selectionRows, 'onPreviewSelectChange')
      // this.selectedRowKeys = selectedRowKeys
      // this.selectionRows = selectionRows
    },
    onConfirm(id) {
      console.log('guide-base onConfirm', { id })
      this.selectedRowKeys = [...this.selectedRowKeys, id]
      console.log('onConfirm', { id, 'this.dataSource': this.dataSource, 'this.selectedRowKeys': this.selectedRowKeys })
      let isAllConfirm = this.dataSource.map(({ id }) => id).every(id => this.selectedRowKeys.includes(id))
      if (isAllConfirm) {
        console.log('全部勾选了!!!')
        this.$emit('tableConfirm', this.propsFilters)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@assets/less/common.less';
/deep/ .border-bottom {
  border: none;
}
/deep/ .ant-table-row-cell-break-word {
  border-top: 1px solid #e8e8e8 !important;
}
</style>
